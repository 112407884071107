
@tailwind base;
@tailwind components;
@tailwind utilities;

.text-primary-inverted {
    color: var(--hr-primary-inverted);
}

.bg-primary-inverted {
    background-color: var(--hr-primary-inverted);
}

.border-primary-inverted {
    border-color: var(--hr-primary-inverted);
}

.border-primary {
    border-color: var(--hr-primary);
}
